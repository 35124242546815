@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;600;700&display=swap');

@media (prefers-color-scheme: light) {
  /*
  https://leonardocolor.io/theme?name=Untitled&config=%7B%22baseScale%22%3A%22Gray%22%2C%22colorScales%22%3A%5B%7B%22name%22%3A%22Gray%22%2C%22colorKeys%22%3A%5B%22%23000000%22%5D%2C%22colorspace%22%3A%22RGB%22%2C%22ratios%22%3A%5B%221.14%22%2C%221.68%22%2C%223.02%22%2C%225%22%2C%2210%22%2C%2221%22%5D%2C%22smooth%22%3Afalse%7D%2C%7B%22name%22%3A%22Gold%22%2C%22colorKeys%22%3A%5B%22%23e69d00%22%2C%22%23ffda8a%22%2C%22%235c3a00%22%5D%2C%22colorspace%22%3A%22RGB%22%2C%22ratios%22%3A%5B%221.14%22%2C%221.68%22%2C%223.02%22%2C%225%22%2C%2210%22%2C%2221%22%5D%2C%22smooth%22%3Afalse%7D%5D%2C%22lightness%22%3A100%2C%22contrast%22%3A1%2C%22saturation%22%3A100%2C%22formula%22%3A%22wcag2%22%7D
  https://leonardocolor.io/theme?name=Untitled&config=%7B%22baseScale%22%3A%22blueprint%22%2C%22colorScales%22%3A%5B%7B%22name%22%3A%22blueprint%22%2C%22colorKeys%22%3A%5B%22%231f4b84%22%5D%2C%22colorspace%22%3A%22OKLCH%22%2C%22ratios%22%3A%5B%221%22%2C%221.5%22%2C%222%22%2C%223.02%22%2C%225%22%2C%228%22%5D%2C%22smooth%22%3Atrue%7D%5D%2C%22lightness%22%3A93%2C%22contrast%22%3A1%2C%22saturation%22%3A100%2C%22formula%22%3A%22wcag2%22%7D
  */
  :root {
    --Gray100: #efefef;
    --Gray200: #c7c7c7;
    --Gray250: #acacac;
    --Gray300: #949494;
    --Gray400: #6f6f6f;
    --Gray500: #424242;
    --Gray600: #000000;

    --Gold100: #ffefcd;
    --Gold200: #efb332;
    --Gold300: #c88700;
    --Gold400: #986500;
    --Gold500: #5d3b00;
    --Gold600: #000000;

    --blueprint100: var(--Gray100);

    --appBackground: #ffffff;
    --appBackgroundDepressed: var(--Gray100);
    --background: #e4ebf3;
    --legendColor: #000000;
    --legendTextColor: #ffffff;
    --dialogBackground: var(--appBackground);
    --linkColor: var(--Gold400);

    /* --blueprint100: #e3eaf3;
    --blueprint200: #afc3dd;
    --blueprint300: #8ea9cd;
    --blueprint400: #6588b8;
    --blueprint500: #3b649c;
    --blueprint600: #19447d; */

    --shadowColor: rgb(0 0 0 / 8%);
    --elevationDemoShadowColor: rgb(0 0 0 / 20%);
    --modalOverlayColor: rgba(0, 0, 0, 0.2);
    --elevationItemBackground: var(--appBackground);
    --containerItemBackground: var(--appBackground);
    --selectionIndicatorColor: var(--Gold300);
    --logoColor: var(--Gold300);
  }
}
@media (prefers-color-scheme: dark) {
  /*. 
  https://leonardocolor.io/theme?name=Untitled&config=%7B%22baseScale%22%3A%22Gray%22%2C%22colorScales%22%3A%5B%7B%22name%22%3A%22Gray%22%2C%22colorKeys%22%3A%5B%22%23000000%22%5D%2C%22colorspace%22%3A%22RGB%22%2C%22ratios%22%3A%5B%221.14%22%2C%221.87%22%2C%223.02%22%2C%225%22%2C%2210%22%2C%2221%22%5D%2C%22smooth%22%3Afalse%7D%2C%7B%22name%22%3A%22Gold%22%2C%22colorKeys%22%3A%5B%22%23e69d00%22%2C%22%23ffda8a%22%2C%22%235c3a00%22%5D%2C%22colorspace%22%3A%22RGB%22%2C%22ratios%22%3A%5B%221.14%22%2C%221.87%22%2C%223.02%22%2C%225%22%2C%2210%22%2C%2221%22%5D%2C%22smooth%22%3Afalse%7D%5D%2C%22lightness%22%3A8%2C%22contrast%22%3A1%2C%22saturation%22%3A100%2C%22formula%22%3A%22wcag2%22%7D
  https://leonardocolor.io/theme?name=Untitled&config=%7B%22baseScale%22%3A%22blueprint%22%2C%22colorScales%22%3A%5B%7B%22name%22%3A%22blueprint%22%2C%22colorKeys%22%3A%5B%22%231f4b84%22%5D%2C%22colorspace%22%3A%22OKLCH%22%2C%22ratios%22%3A%5B%221%22%2C%221.5%22%2C%222%22%2C%223.02%22%2C%225%22%2C%228%22%5D%2C%22smooth%22%3Atrue%7D%5D%2C%22lightness%22%3A12%2C%22contrast%22%3A1%2C%22saturation%22%3A70%2C%22formula%22%3A%22wcag2%22%7D
  */
  :root {
    --Gray100: #242424;
    --Gray200: #454545;
    --Gray250: #585858;
    --Gray300: #646464;
    --Gray400: #878787;
    --Gray500: #c1c1c1;
    --Gray600: #ffffff;

    --Gold100: #332000;
    --Gold200: #613e00;
    --Gold300: #895b00;
    --Gold400: #b77b00;
    --Gold500: #f1b83e;
    --Gold600: #ffffff;

    --blueprint100: var(--Gray200);

    --appBackground: var(--Gray100);
    --appBackgroundDepressed: #171717;
    --legendColor: #323232;
    --legendTextColor: #ffffff;
    --background: #001e44;
    --dialogBackground: var(--Gray100);
    --linkColor: var(--Gold500);

    /* --blueprint100: #011e46;
    --blueprint200: #19457e;
    --blueprint300: #325c95;
    --blueprint400: #587db0;
    --blueprint500: #8da8cd;
    --blueprint600: #c8d5e8; */

    --shadowColor: rgb(0 0 0 / 50%);
    --elevationDemoShadowColor: rgb(0 0 0 / 50%);
    --modalOverlayColor: rgba(0, 0, 0, 0.5);
    --elevationItemBackground: var(--Gray200);
    --containerItemBackground: var(--Gray100);
    --selectionIndicatorColor: var(--Gold500);
    --logoColor: var(--Gold500);
  }
}

:root {
  --blueprint150: var(--Gray200);
  --blueprint200: var(--Gray250);
  --blueprint300: var(--Gray300);
  --blueprint400: var(--Gray400);
  --blueprint500: var(--Gray600);
  --blueprint600: var(--Gray600);

  --paddingColor: #ff00ff;

  --fieldSetBackground: var(--Gray100);
  --border100: var(--Gray200);
  --border200: var(--Gray300);
  --textColor: var(--Gray600);
  --textColorTertiary: var(--Gray500);
  --panelBorderRadius: 24px;
  --panelWidth: 418px;
  --panelShadow: 0 4px 8px 0 var(--shadowColor);
  --menuShadow: 0 4px 8px 0 var(--shadowColor);
  --fabShadow: 0 8px 12px 0 var(--shadowColor);
  --appFrameMargin: 24px;
  --headerHeight: 56px;
  --fontStack: 'Inter', sans-serif;

  --focusRing: 0 0 0 2px var(--appBackground), 0 0 0 4px var(--Gold300);
  /* --fontFamily: "Inter", sans-serif; */
  --fontFamily: 'Outfit', sans-serif;
  --defaultFontWeight: 400;
}

::-moz-selection {
  /* Code for Firefox */
  background: var(--Gold100);
}

::selection {
  background: var(--Gold100);
}

html {
  font-family: var(--fontFamily);
  background-color: var(--appBackgroundDepressed);
  color: var(--textColor);
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
button,
input,
label,
legend {
  font-family: var(--fontFamily);
}
h1 {
  font-size: 3em;
  margin-top: 1em;
  margin-bottom: 0.125em;
}
h2 {
  font-size: 2em;
  margin-bottom: 0.25em;
}
h3 {
  margin-top: 0;
}
h4 {
  margin-top: 0.5em;
  margin-bottom: 0;
}
h5 {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 0.76em;
}

h1.display1 {
  font-size: 2.5em;
}

article h1 {
  margin-top: 0.5em;
}

article p {
  font-size: 1.25em;
  max-width: 66ch;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}

article li {
  font-size: 1.125em;
}

p.body1 {
  font-size: 1.5rem;
}

article + article {
  margin-top: 2rem;
}

.splitView {
  display: grid;
  grid-template-areas:
    'main'
    'panel';
  grid-template-rows: 'auto auto';
  gap: var(--appFrameMargin);
  padding: 0;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}
@media screen and (min-width: 900px) {
  .splitView {
    grid-template-areas: 'panel main';
    grid-template-columns: var(--panelWidth) auto;
    padding: var(--appFrameMargin) var(--appFrameMargin) 0 0;
    height: calc(100% - var(--appFrameMargin));
  }
}

main {
  grid-area: main;
  display: flex;
  padding: 24px 48px 48px 48px;
  border-radius: var(--panelBorderRadius);
  background-color: var(--appBackground);
  color: var(--blueprint500);
  margin: var(--appFrameMargin);
  box-shadow: var(--panelShadow);
}

main.demoRow {
  flex-direction: row;
  column-gap: calc(var(--appFrameMargin) * 5);
  row-gap: var(--appFrameMargin);
  flex-wrap: wrap;
  padding-top: 48px;
  align-content: flex-start;
}
main.demoRow .tabs_action {
  width: 100%;
  margin: 0;
}
main.demoRow .column {
  flex-grow: 0;
}

.splitView main {
  margin: 0;
  width: 100%;
  height: auto;
  overflow-x: scroll;
  margin: var(--appFrameMargin);
  max-width: calc(100vw - 144px);
}
@media screen and (min-width: 900px) {
  .splitView main {
    max-width: calc(100vw - 538px - var(--appFrameMargin));
    height: calc(100vh - 200px);
    margin: 0;
    row-gap: 56px;
  }
  .splitView main.demoRow--compact {
    row-gap: 16px;
  }
}
.splitView main.main--content {
  height: fit-content;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.mainPageContent {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  max-width: 800px;
  margin: var(--appFrameMargin) auto;
  padding-top: 8px;
  margin-top: -64px;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}
.row--spacious {
  /* gap: 32px; */
  column-gap: calc(var(--appFrameMargin) * 5);
  row-gap: 32px;
}
.row--comfortable {
  gap: 32px;
}
.column {
  display: flex;
  flex-direction: column;
  gap: 0px;
  min-width: fit-content;
  flex-grow: 1;
}
.column--fitContent {
  max-width: fit-content;
}

.Panel_tabsList {
  padding: 0 24px;
  display: flex;
  flex-direction: row;
  appearance: none;
  list-style: none;
  margin: 0;
  gap: 8px;
  color: var(--textColor);
  position: absolute;
  margin-top: 12px;
  font-size: 0.875rem;
}

.Panel_tab {
  padding: 8px 0px;
  cursor: pointer;
  border-bottom: 4px solid transparent;
}

.Panel_tab.react-tabs__tab--selected {
  border-color: var(--textColor);
}

.App_tabs {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: var(--headerHeight) calc(100vh - var(--headerHeight));
  overflow: auto;
  grid-template-areas:
    'header'
    'content';
  /* overflow: hidden; */
}
.App_tabsList {
  display: flex;
  flex-direction: row;
  appearance: none;
  list-style: none;
  margin: 0;
  padding: 0;
  color: var(--textColor);
}
.App_tab {
  height: 24px;
  display: flex;
  line-height: 1.4;
  align-items: baseline;
  padding: 8px 10px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 8px;
  transition: all ease-in-out 0.1s;
  /* border-bottom: 4px solid transparent; */
}
/* .App_tab:hover,
.App_tab:active {
  background-color: var(--Gold100);
  transition: all ease-in-out 0.1s;
} */
.App_tab.react-tabs__tab--selected {
  /* border-color: var(--selectionIndicatorColor); */
  background-color: var(--selectionIndicatorColor);
  color: var(--appBackground);
  transition: all ease-in-out 0.3s;
}
.App-tab:focus-visible,
.App-tab:focus {
  outline: none;
  box-shadow: var(--focusRing);
  border-radius: 4px;
}
.App_tabPanel {
  grid-area: content;
}
.App_tabPanel.react-tabs__tab-panel--selected {
  z-index: 10;
}

.codeOutput {
  display: none;
  flex-direction: row;
  gap: 32px;
  align-items: flex-start;
  max-height: calc(100% - 64px);
}
.codeOutput.react-tabs__tab-panel--selected {
  display: flex;
}

.__json-pretty__ {
  line-height: 1.3;
  color: var(--Gray500);
  background: var(--Gray100);
  padding: 16px;
  border-radius: 8px;
  overflow: auto;
}
pre {
  margin-top: 0;
}

button {
  appearance: none;
  padding: 6px 9px;
  font-size: 0.875rem;
  border-radius: 6px;
  background: var(--Gray100);
  color: var(--Gray600);
  border: 1px solid var(--Gray300);
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}
button:hover,
button:active {
  background: var(--Gray200);
}
button:focus {
  box-shadow: 0 0 0 2px var(--appBackground),
    0 0 0 4px var(--selectionIndicatorColor);
}
button.clearButton {
  background: transparent;
  border: none;
}
button.clearButton:hover,
button.clearButton:active,
button.clearButton:focus {
  background: var(--Gold100);
}

.typeItem,
.component {
  font-weight: var(--demoFontWeight, 400);
}

cite {
  font-size: 12px;
  color: var(--textColorTertiary);
}

a,
a:visited {
  color: var(--linkColor);
}
