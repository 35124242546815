#typography {
  display: flex;
  flex-direction: column-reverse;
}

.typeItem {
  display: grid;
  grid-template-columns: 64px max-content;
  align-items: center;
  line-height: 1.25; /* Same value used in iconElement to calculate min height of icon in pairing */
}

.specs {
  font-size: 12px;
  font-style: italic;
  align-items: center;
}
