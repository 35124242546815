.react-tabs {
  width: 100%;
}

.react-tabs__tab-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  appearance: none;
  list-style: none;
  margin: 0;
  padding: 0;
  color: var(--textColor);
  margin-bottom: var(--appFrameMargin);
}

.react-tabs__tab {
  padding: 8px 10px;
  cursor: pointer;
  border-bottom: 4px solid transparent;
}
/* .react-tabs__tab:hover,
.react-tabs__tab:active {
  background-color: var(--Gold100);
} */
.react-tabs__tab:focus-visible,
.react-tabs__tab:focus {
  outline: none;
  box-shadow: var(--focusRing);
  border-radius: 4px;
}

.react-tabs__tab--selected {
  border-color: var(--selectionIndicatorColor);
}

.react-tabs__tab-panel {
  display: flex;
  flex-direction: row;
  column-gap: calc(var(--appFrameMargin) * 3);
  row-gap: var(--appFrameMargin);
  flex-wrap: wrap;
}

.tabs_action {
  height: 36px;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  margin-left: var(--appFrameMargin);
}

.tabs_action fieldset {
  margin: 0;
  padding: 0;
}

.panel .react-tabs__tab {
  flex-grow: 1;
  text-align: center;
}
