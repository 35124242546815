.componentColumn {
  display: flex;
  flex-direction: column-reverse;
}

.componentSpecWrapper {
  display: grid;
  grid-template-columns: 92px max-content max-content 80px;
  grid-template-rows: max-content;
  grid-template-areas:
    '. spec00 spec0 .'
    'spec1 component component spec2 '
    '. spec3 spec4 spec5';
  border: 1px dashed var(--Gold200);
  padding: 4px 16px;
  width: max-content;
}
.specRowItem {
  display: grid;
  grid-template-columns: 56px max-content;
  gap: 12px;
  margin-bottom: var(--appFrameMargin);
}

.specRowItem h5 {
  align-self: center;
}
.componentWrapper {
  display: flex;
}
.componentSpecItemWrapper {
  grid-area: component;
  display: flex;
  align-items: center;
}
.component {
  position: relative;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-areas:
    '. padTopAlt padTop padTop . .'
    'padLeft icon textIconGap label . padRight'
    '. padBottomAlt padBottom padBottom . radius';
  grid-template-columns: max-content;
  width: max-content;
  height: max-content;
  background-color: var(--blueprint100);
  border: 1px solid var(--blueprint300);
  border-radius: 6px;
  font-weight: 500; /* Demi */
}
.componentColumn_Heading {
  margin-left: 68px;
  margin-bottom: 0.5em;
}
.compRadius {
  grid-area: radius;
  justify-self: flex-end;
  align-self: flex-end;
  position: absolute;
}
.paddingUnit {
  background-color: var(--paddingColor);
  opacity: 0.5;
}
.padTop {
  grid-area: padTop;
  margin-left: -18%;
}
.padBottom {
  grid-area: padBottom;
  margin-left: -18%;
}
.padTopAlt {
  grid-area: padTopAlt;
}
.padBottomAlt {
  grid-area: padBottomAlt;
}
.padLeft {
  grid-area: padLeft;
}
.padRight {
  grid-area: padRight;
}
.textIconGap {
  grid-area: textIconGap;
  min-height: 8px;
}
.componentTextSpec {
  font-weight: var(--demoFontWeight);
  grid-area: label;
  background-color: var(--blueprint150);
}
.componentIconSpec {
  display: flex;
  grid-area: icon;
  background-color: var(--blueprint150);
}
.componentText {
  font-weight: var(--demoFontWeight);
  grid-area: label;
}
.componentIcon {
  display: flex;
  grid-area: icon;
}

.specs {
  font-family: var(--fontFamily) !important;
  font-weight: var(--defaultFontWeight);
  display: flex;
  align-items: center;
  font-size: 12px;
  font-style: italic;
  line-height: 1.5;
  color: var(--Gold400);
}

.specHeight {
  position: relative;
  grid-area: spec1;
  border-right: 1px solid var(--Gold400);
  height: 100%;
  margin-right: 10px;
}
.specHeight::after {
  content: ' ';
  display: block;
  width: 4px;
  height: calc(100% - 2px);
  border-top: 1px solid var(--Gold400);
  border-bottom: 1px solid var(--Gold400);
  right: -4px;
  position: absolute;
}

.specPaddingX {
  position: relative;
  grid-area: spec2;
  height: 100%;
  margin-left: 16px;
}
.specPaddingX::before {
  content: ' ';
  display: block;
  width: 16px;
  height: 1px;
  border-top: 1px solid var(--Gold400);
  left: -18px;
  position: absolute;
}

.specPaddingY {
  position: relative;
  display: flex;
  justify-content: flex-start;
  grid-area: spec0;
  width: 100%;
  text-align: center;
  margin-bottom: 8px;
}
.specPaddingY::after {
  content: ' ';
  display: block;
  width: 6%;
  height: 16px;
  border-left: 1px solid var(--Gold400);
  border-top: 1px solid var(--Gold400);
  bottom: -8px;
  left: -10%;
  position: absolute;
}

.specGap {
  position: relative;
  display: flex;
  justify-content: flex-start;
  grid-area: spec00;
  width: 100%;
  text-align: center;
  margin-bottom: 8px;
}
.specGap::after {
  content: ' ';
  display: block;
  width: 1px;
  height: 32px;
  border-left: 1px solid var(--Gold400);
  bottom: -36px;
  position: absolute;
  left: 19%;
}

.specType {
  position: relative;
  grid-area: spec4;
  width: max-content;
  height: 18px;
  margin-top: 8px;
  margin-left: 8px;
}
.specType::after {
  content: ' ';
  display: block;
  height: 24px;
  border-right: 1px solid var(--Gold400);
  position: absolute;
  top: -24px;
  left: 12px;
}

.specIcon {
  position: relative;
  grid-area: spec3;
  width: 100%;
  justify-content: flex-start;
  height: 18px;
  margin-left: 18%;
  margin-right: 8px;
  margin-top: 8px;
}
.specIcon::after {
  content: ' ';
  display: block;
  height: 32px;
  border-right: 1px solid var(--Gold400);
  position: absolute;
  top: -32px;
  left: 8%;
}

.specRadius {
  grid-area: spec5;
}
