:root {
  --segmentedControlRadius: 8px;
}

div[id^='font-picker-main'],
.Dropdown-root {
  border: none;
  box-shadow: none !important;
  width: 100% !important;
  position: relative;
}

input,
div[id^='font-picker-main'] .dropdown-button,
.Dropdown-control {
  box-shadow: none;
  outline: none;
  background-color: var(--appBackground);
  border: 1px solid var(--border200);
  color: var(--textColor);
  border-radius: 6px;
  padding: 4px 6px;
  font-size: 0.875rem;
  accent-color: var(--Gold400);
}

div[id^='font-picker-main'] .dropdown-button,
.dropdown-font-family {
  font-family: inherit;
}
div[id^='font-picker-main'] .dropdown-button,
.Dropdown-control {
  background-color: var(--appBackground) !important;
  padding: 4px 6px !important;
  max-height: 28px !important;
}

div[id^='font-picker'] ul,
.Dropdown-menu {
  background-color: var(--appBackground) !important;
  box-shadow: var(--menuShadow) !important;
  border: 1px solid var(--border200);
  border-radius: 6px;
  top: 28px;
  z-index: 10;
  opacity: 0;
  transition: all 0.3s ease-out;
  z-index: 10;
}
.Dropdown-menu {
  position: absolute;
  width: 100%;
}
div[id^='font-picker'].expanded ul,
.Dropdown-root.is-open .Dropdown-menu {
  max-height: 200px;
  overflow: auto;
  top: 32px;
  opacity: 1;
  transition: all 0.3s ease-out;
}
div[id^='font-picker'] ul li button {
  border-radius: 0;
}
div[id^='font-picker'] ul li button:hover,
div[id^='font-picker'] ul li button:focus {
  background: var(--Gold100) !important;
}
.Dropdown-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  padding: 4px 6px;
  font-size: 0.875rem;
  height: 28px;
  cursor: pointer;
}
.Dropdown-option:hover,
.Dropdown-option:active {
  background-color: var(--Gold100);
}
.is-open .Dropdown-arrow {
  transform: rotate(-180deg);
}
div[id^='font-picker'] .dropdown-icon.finished::before {
  border-top-color: var(--textColor) !important;
}
.Dropdown-arrow {
  border-top: 6px solid var(--textColor);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: ' ';
  display: block;
  height: 0;
  margin: 0;
  position: absolute;
  right: 10px;
  top: 11px;
  width: 0;
  transition: transform 0.3s;
}

input[type='number'] {
  width: 6ch;
}
input:focus {
  box-shadow: var(--focusRing);
}

input[type='radio'] {
  display: none;
}
input[type='radio'] + label {
  padding: 4px 8px;
  width: 100%;
}
.segmentedControl {
  display: flex;
  width: 100%;
  height: 28px;
  flex-direction: row;
  gap: 0;
  border-radius: var(--segmentedControlRadius);
}
.radioGroup {
  display: flex;
  flex-grow: 1;
  text-align: center;
}
.radioGroup input[type='radio'] + label {
  border: 1px solid var(--border100);
}
.radioGroup + .radioGroup input[type='radio'] + label {
  border-left: none;
}
.radioGroup:first-of-type input[type='radio'] + label {
  border-top-left-radius: var(--segmentedControlRadius);
  border-bottom-left-radius: var(--segmentedControlRadius);
}
.radioGroup:last-of-type input[type='radio'] + label {
  border-top-right-radius: var(--segmentedControlRadius);
  border-bottom-right-radius: var(--segmentedControlRadius);
}
input[type='radio']:checked + label {
  background-color: var(--selectionIndicatorColor);
  border-color: var(--selectionIndicatorColor);
  color: var(--appBackground);
}

input[type='range'] {
  padding: 0;
  height: auto;
  margin: 0;
  align-self: flex-end;
}

fieldset {
  display: flex;
  flex-direction: row;
  flex-basis: fit-content;
  flex-shrink: 0;
  gap: 24px;
  margin: 16px 0;
  outline: none;
  box-shadow: none;
  border: 0;
  border-right: 1px solid var(--border100);
  border-radius: 0;
  padding: 10px 16 16px 0;
  flex-wrap: wrap;
  width: 180px;
}
fieldset:last-of-type {
  border-bottom: 0;
}
@media screen and (min-width: 900px) {
  fieldset {
    border-bottom: 1px solid var(--border100);
    border-right: none;
    padding: 16px 0 24px 0;
    width: auto;
  }
  fieldset:last-of-type {
    border-right: 0;
  }
  .panel .react-tabs .react-tabs__tab-panel fieldset {
    flex-grow: 1;
  }
}
fieldset.column {
  flex-direction: column;
  gap: 8px;
}
fieldset section {
  display: flex;
  flex-direction: row;
  gap: 24px;
}
legend {
  padding: 0;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
}

legend cite {
  font-weight: 400;
}

label {
  font-size: 0.875rem;
}

.formGroup {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 8px;
}

.slider {
  display: grid;
  grid-template-columns: 128px 48px;
  gap: 8px;
}
.formGroup .slider {
  align-self: flex-end;
}
.slider_value {
  text-align: right;
  font-size: 0.875rem;
}
.checkboxGroup {
  height: 32px;
  display: flex;
  align-items: center;
  gap: 4px;
}
