.panel {
  grid-area: panel;
  background-color: var(--appBackground);
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  box-shadow: var(--panelShadow);
  margin-bottom: 0;
  z-index: 10;
  overflow: auto;
  gap: var(--appFrameMargin);
}
@media screen and (min-width: 900px) {
  .panel {
    border-top-right-radius: var(--panelBorderRadius);
    overflow-y: auto;
    flex-direction: column;
    min-width: fit-content;
    width: calc(var(--panelWidth) - (var(--appFrameMargin) * 2));
    gap: 0;
    height: calc(100% - 32px);
  }
}

.panel--fixed {
  position: fixed;
  width: 232px;
}

.panel .react-tabs__tab-panel {
  gap: 0;
}
