#radiusWrapper {
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
}
.radiusItem {
  display: grid;
  grid-template-columns: 20px auto;
  font-size: 12px;
  font-style: italic;
  align-items: center;
  gap: 8px;
}
.radius {
  width: 80px;
  height: 80px;
  background-color: var(--blueprint100);
  border-left: 1px solid var(--blueprint400);
  border-top: 1px solid var(--blueprint400);
}
