#iconography {
  display: flex;
  flex-direction: column-reverse;
}
.iconItem {
  display: grid;
  grid-template-columns: 64px max-content;
  font-size: 12px;
  font-style: italic;
  align-items: center;
}

.icon {
  display: flex;
  background-color: var(--blueprint100);
  border-radius: 2px;
  align-items: center;
}
