header {
  grid-area: header;
  background-color: var(--appBackground);
  display: flex;
  /* height: 54px; */
  align-items: center;
  /* box-shadow: 0 4px 16px var(--shadowColor); */
  padding: 8px 24px;
  justify-content: space-between;
  gap: 36px;
  box-shadow: var(--panelShadow);
  z-index: 20;
}

.logo {
  font-family: 'Outfit', sans-serif;
  margin: 0;
  display: flex;
  line-height: 1.4;
  height: 100%;
  align-items: center;
  font-weight: 800;
  font-size: 1.25em;
  /* padding-top: 2px; */
}

.logoLockup {
  display: flex;
  flex-direction: row;
  gap: 4px;
  height: 100%;
  align-items: center;
}

.header--left {
  display: flex;
  flex-direction: row;
  gap: var(--appFrameMargin);
  align-items: center;
}

.header--right {
  justify-self: flex-end;
  display: flex;
  align-items: center;
  gap: var(--appFrameMargin);
}
.header--right a {
  display: flex;
  align-items: center;
}
.header--center {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.header--left .formGroup,
.header--center .formGroup,
.header--right .formGroup {
  margin-bottom: 0;
}

#unitPicker {
  width: 6ch;
}
