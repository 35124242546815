#spacingWrapper {
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
}
.spacingItem {
  display: grid;
  grid-template-columns: 32px auto;
  font-size: 12px;
  font-style: italic;
  align-items: center;
  gap: 8px;
}
.spacing {
  background-color: var(--blueprint200);
}
