#typeIconPairing {
  display: flex;
  flex-direction: column-reverse;
}

.textIconItem {
  display: grid;
  grid-template-columns: 90px auto;
  align-items: center;
}

.typeIconPair {
  display: flex;
  flex-direction: row;
  align-items: start;
}

.typeIconPair .iconItem,
.typeIconPair .typeItem {
  display: flex;
}

.typeIconPair .icon {
  background-color: transparent;
}
